import React from "react";
import './App.css';

function App() {
  const words = ['sky', 'blue', 'falcon', 'wood', 'cloud'];

  // create react hook array
  const [word, setWord] = React.useState([]);


  const addTextInputToArr = (e) => {
    e.preventDefault();
    console.log(e)
    const input = e.target.elements.input.value.trim();
    console.log(input)
    // if (input.length > 0) {
    // add input to word array
    setWord([...word, input]);
    
    console.log(word)

  }



  return (
    <div className="App">
      <header className="App-header">
        <h1>Create Count Down</h1>
        
        {/* Button that runs addTextInputToArr */}
        <form onSubmit={addTextInputToArr}>
          <input type="text" name="input" />
          <button>Add</button>
        </form>

        
        <ul>
          {word.map((word, index) => (
            <li key={index}>{word}</li>
          ))}
        </ul>
        
        
        {/* <ul>
        {words.map((item) => (
          <li key={item.id}>{item.name}</li>
        ))}
        </ul> */}

        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
